import { Project } from '../../Project';
import { ALIA, clients } from '../../clients';
import { Video } from '../../Video';

import travelReelThumbnail from '../../../assets/img/stills/alia/travel_reel/Thumb_1920x384_AliaTravel_v2.jpg';

export const travelReel = new Project({
  name: 'Travel Reel',
  url: 'travelreel',
  client: clients.get(ALIA),
  videos: [
    new Video({
      vimeoID: '268724008/acb063478b',
      name: 'Travel Reel',
    }),
  ],
  thumbnail: travelReelThumbnail,
  isPublic: false,
});
