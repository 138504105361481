import React from 'react';
import { Row as AntRow, Col } from 'antd/lib/grid';
import 'antd/lib/grid/style/index.css';

const Row = (props) => (
  <AntRow
    type="flex"
    justify="center"
    gutter={32}
    style={{ marginLeft: 0, marginRight: 0 }}
    {...props}
  />
);

export { Row, Col };
