import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../components/Grid/Grid';
import { clientArray } from '../../models/clients';

export class ClientList extends Component {
  render() {
    return (
      <div>
        <Helmet title="alia | Clients" />
        <Row gutter={0} style={{ textAlign: 'center' }}>
          <Col xs={23} sm={23} md={19} lg={18} xl={18}>
            <h1>Clients</h1>
          </Col>
        </Row>
        <ul>
          {clientArray.map((client) => (
            <Link
              to={`/client/${encodeURIComponent(client.url)}`}
              key={client.name}
            >
              <div>{client.name}</div>
            </Link>
          ))}
        </ul>
      </div>
    );
  }
}
