import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'antd/lib/layout';
import { Routes } from '../../Routes';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { ScrollToTop } from '../../components/ScrollToTop/ScrollToTop';
import './App.css';

export class App extends Component {
  render() {
    return (
      <Layout className="App">
        <Router>
          <ScrollToTop>
            <div>
              <Header />
              <Layout.Content>
                <Routes />
              </Layout.Content>
              <Footer />
            </div>
          </ScrollToTop>
        </Router>
      </Layout>
    );
  }
}
