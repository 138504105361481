import { Project } from '../../Project';
import { clients, LAKEFIELD_COLLEGE_SCHOOL } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/lakefield_college_school/athletics_spring/Thumb_1920x384_LCSAthSpring_v2.jpg';
import athleticsSpring1 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-001-1.jpg';
import athleticsSpring2 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-002-2.jpg';
import athleticsSpring3 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-005-3.jpg';
import athleticsSpring4 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-006-4.jpg';
import athleticsSpring5 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-009-5.jpg';
import athleticsSpring6 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-011-1.jpg';
import athleticsSpring7 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-014-7.jpg';
import athleticsSpring8 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-017-2.jpg';
import athleticsSpring9 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180522-LCS-023-9.jpg';
import athleticsSpring10 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-002-18.jpg';
import athleticsSpring11 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-007-19.jpg';
import athleticsSpring12 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-022-20.jpg';
import athleticsSpring13 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-028-21.jpg';
import athleticsSpring14 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-030-22.jpg';
import athleticsSpring15 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-035-23.jpg';
import athleticsSpring16 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-040-24.jpg';
import athleticsSpring17 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-045-25.jpg';
import athleticsSpring18 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-052-26.jpg';
import athleticsSpring19 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180523-LCS-057-4.jpg';
import athleticsSpring20 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-004-3.jpg';
import athleticsSpring21 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-010-11.jpg';
import athleticsSpring22 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-011-12.jpg';
import athleticsSpring23 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-014-13.jpg';
import athleticsSpring24 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-019-14.jpg';
import athleticsSpring25 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-022-15.jpg';
import athleticsSpring26 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-028-16.jpg';
import athleticsSpring27 from '../../../assets/img/stills/lakefield_college_school/athletics_spring/20180524-LCS-031-17.jpg';

export const athleticsSpring = new Project({
  name: 'Athletics Spring',
  url: 'athleticsspring',
  client: clients.get(LAKEFIELD_COLLEGE_SCHOOL),
  videos: [
    new Video({
      vimeoID: '294960588',
      name: 'Athletics Spring',
    }),
  ],
  stills: [
    athleticsSpring1,
    athleticsSpring2,
    athleticsSpring3,
    athleticsSpring4,
    athleticsSpring5,
    athleticsSpring6,
    athleticsSpring7,
    athleticsSpring8,
    athleticsSpring9,
    athleticsSpring10,
    athleticsSpring11,
    athleticsSpring12,
    athleticsSpring13,
    athleticsSpring14,
    athleticsSpring15,
    athleticsSpring16,
    athleticsSpring17,
    athleticsSpring18,
    athleticsSpring19,
    athleticsSpring20,
    athleticsSpring21,
    athleticsSpring22,
    athleticsSpring23,
    athleticsSpring24,
    athleticsSpring25,
    athleticsSpring26,
    athleticsSpring27,
  ],
  thumbnail,
});
