import { Project } from '../../Project';
import { clients, DESTINATION_ONTARIO } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/destination_ontario/relax_and_rewind/Thumb_1920x384_DON_1053_SpaJan.jpg';

export const relaxAndRewind = new Project({
  name: 'Relax and Rewind',
  url: 'relaxandrewind',
  client: clients.get(DESTINATION_ONTARIO),
  videos: [
    new Video({
      vimeoID: '312454438/ffbcbd8a58',
      name: 'Relax and Rewind',
    }),
    new Video({
      vimeoID: '311584740/272fb83212',
      name: 'Relax and Rewind 2',
    }),
  ],
  thumbnail,
  isPublic: false,
});
