import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Layout from 'antd/lib/layout';
import { Logo } from '../Logo/Logo';
import './Header.css';

export class Header extends Component {
  render() {
    return (
      <Layout.Header>
        <div className="header-spacer" />
        <nav className="header-navigation">
          <Link to="/">
            <Logo />
          </Link>
          <ul>
            <li key="work">
              <NavLink to="/work" activeClassName="header-navigation-selected">
                Work
              </NavLink>
            </li>
            <li key="about">
              <NavLink
                to="/about"
                activeClassName="header-navigation-selected"
                exact
              >
                About
              </NavLink>
            </li>
            <li key="connect">
              <NavLink
                to="/connect"
                activeClassName="header-navigation-selected"
                exact
              >
                Connect
              </NavLink>
            </li>
          </ul>
        </nav>
      </Layout.Header>
    );
  }
}
