import { Project } from '../../Project';
import { clients, DESTINATION_ONTARIO } from '../../clients';
import { Video } from '../../Video';

import bootsHeartsThumbnail from '../../../assets/img/stills/boots_hearts/Thumb_1920x384_BootsHearts.jpg';
import bootsHeartsImage1 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_001_small.jpg';
import bootsHeartsImage2 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_004_small.jpg';
import bootsHeartsImage3 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_007_small.jpg';
import bootsHeartsImage4 from '../../../assets/img/stills/boots_hearts/bts_boots_170817_010_small.jpg';
import bootsHeartsImage5 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_002_small.jpg';
import bootsHeartsImage6 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_005_small.jpg';
import bootsHeartsImage7 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_008_small.jpg';
import bootsHeartsImage8 from '../../../assets/img/stills/boots_hearts/bts_boots_170817_011_small.jpg';
import bootsHeartsImage9 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_003_small.jpg';
import bootsHeartsImage10 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_006_small.jpg';
import bootsHeartsImage11 from '../../../assets/img/stills/boots_hearts/bts_boots_170812_009_small.jpg';
import bootsHeartsImage12 from '../../../assets/img/stills/boots_hearts/bts_boots_170817_012_small.jpg';

export const bootsAndHearts = new Project({
  name: 'Boots & Hearts',
  videos: [
    new Video({
      vimeoID: '273994333/0b3a2da6df',
      name: 'Boots & Hearts',
    }),
  ],
  url: 'bootsandhearts',
  client: clients.get(DESTINATION_ONTARIO),
  isPublic: false,
  stills: [
    bootsHeartsImage1,
    bootsHeartsImage2,
    bootsHeartsImage3,
    bootsHeartsImage4,
    bootsHeartsImage5,
    bootsHeartsImage6,
    bootsHeartsImage7,
    bootsHeartsImage8,
    bootsHeartsImage9,
    bootsHeartsImage10,
    bootsHeartsImage11,
    bootsHeartsImage12,
  ],
  thumbnail: bootsHeartsThumbnail,
});