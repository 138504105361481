import 'core-js/es6/map';

import { perspective } from './lakefieldCollegeSchool/perspective';
import { seek } from './tesla/seek';
import { colorsOfUbuntu } from './lalela/coloursOfUbuntu';
import { iAmATerrapin } from './lakefieldCollegeSchool/iAmATerrapin';
import { shareTheLove } from './janeWaterous/shareTheLove';
import { athleticsSpring } from './lakefieldCollegeSchool/athleticsSpring';
import { fadingLights } from './alia/fadingLights';
import { foreverTricolour } from './queens/foreverTricolour';
import { veld } from './destinationOntario/veld';
import { bootsAndHearts } from './destinationOntario/bootsAndHearts';
import { carassauga } from './destinationOntario/carassauga';
import {
  alumniWhiskeySeries2018,
  alumniWhiskeySeries2018Stills,
} from './jpWisers/alumniWhiskeySeries2018';
import { elora } from './destinationOntario/elora';
import { reel } from './alia/reel';
import { travelReel } from './alia/travelReel';
import {rebelsWithACause} from "./jack/rebelsWithACause";
import {athleticsWinter} from "./lakefieldCollegeSchool/athleticsWinter";
import {athletics} from "./lakefieldCollegeSchool/athletics";
import {ourTable} from "./jack/ourTable";
import {revolutionNow} from "./jack/revolutionNow";
import {relaxAndRewind} from "./destinationOntario/relaxAndRewind";
import {wasaga} from "./destinationOntario/wasaga";
import {niagara} from "./destinationOntario/niagara";
import {theParentExperience} from "./lakefieldCollegeSchool/theParentExperience";
import {extraordinary} from "./robbinsHebrewAcademy/extraordinary";

export const projects = [
  perspective,
  rebelsWithACause,
  ourTable,
  revolutionNow,
  seek,
  colorsOfUbuntu,
  iAmATerrapin,
  shareTheLove,
  athletics,
  athleticsSpring,
  athleticsWinter,
  fadingLights,
  foreverTricolour,
  veld,
  bootsAndHearts,
  carassauga,
  alumniWhiskeySeries2018,
  alumniWhiskeySeries2018Stills,
  elora,
  relaxAndRewind,
  wasaga,
  niagara,
  reel,
  travelReel,
  theParentExperience,
  extraordinary,
];

export const projectURLs = new Map(
  projects.map((project) => [project.url, project]),
);

export const publicProjects = projects.filter((project) => project.isPublic);
