import { Project } from '../../Project';
import { clients, DESTINATION_ONTARIO } from '../../clients';
import { Video } from '../../Video';

import carassaugaThumbnail from '../../../assets/img/stills/carassauga/Thumb_1920x384_Carassauga_v1.jpg';
import carassaugaImage1 from '../../../assets/img/stills/carassauga/bts_carassauga_180526_001_small.jpg';
import carassaugaImage2 from '../../../assets/img/stills/carassauga/bts_carassauga_180526_002_small.jpg';
import carassaugaImage3 from '../../../assets/img/stills/carassauga/bts_carassauga_180526_003_small.jpg';
import carassaugaImage4 from '../../../assets/img/stills/carassauga/bts_carassauga_180526_004_small.jpg';
import carassaugaImage5 from '../../../assets/img/stills/carassauga/bts_carassauga_180526_005_small.jpg';
import carassaugaImage6 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_006_small.jpg';
import carassaugaImage7 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_007_small.jpg';
import carassaugaImage8 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_008_small.jpg';
import carassaugaImage9 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_009_small.jpg';
import carassaugaImage10 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_010_small.jpg';
import carassaugaImage11 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_011_small.jpg';
import carassaugaImage12 from '../../../assets/img/stills/carassauga/bts_carassauga_180601_012_small.jpg';

export const carassauga = new Project({
  name: 'Carassauga',
  videos: [
    new Video({
      vimeoID: '273910937/53e27e1c8b',
      name: 'Carassauga',
    }),
  ],
  url: 'carassauga',
  client: clients.get(DESTINATION_ONTARIO),
  isPublic: false,
  stills: [
    carassaugaImage1,
    carassaugaImage2,
    carassaugaImage3,
    carassaugaImage4,
    carassaugaImage5,
    carassaugaImage6,
    carassaugaImage7,
    carassaugaImage8,
    carassaugaImage9,
    carassaugaImage10,
    carassaugaImage11,
    carassaugaImage12,
  ],
  thumbnail: carassaugaThumbnail,
});