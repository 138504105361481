import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './VideoCredit.css';

export class VideoCredit extends Component {
  static propTypes = {
    credit: PropTypes.object.isRequired,
  };

  render() {
    const { credit } = this.props;

    return (
      <div key={credit.name}>
        <p>
          <i>{credit.role}</i>
          <br />
          {typeof credit.name === 'string'
            ? credit.name
            : credit.name.map((creditName) => (
              <span>
                  {creditName}
                <br />
                </span>
            ))}
        </p>
      </div>
    );
  }
}