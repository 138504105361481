import { Project } from '../../Project';
import { clients, LAKEFIELD_COLLEGE_SCHOOL } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/lakefield_college_school/the_parent_experience/Thumb_1920x384_LCS_1033_Parents_v3.jpg';

export const theParentExperience = new Project({
  name: 'The Parent Experience',
  url: 'theparentexperience',
  client: clients.get(LAKEFIELD_COLLEGE_SCHOOL),
  videos: [
    new Video({
      vimeoID: '347744490/00e6b33122',
      name: 'The Parent Experience',
    }),
  ],
  thumbnail,
});
