import { Project } from '../../Project';
import { clients, JANE_WATEROUS } from '../../clients';
import { Video } from '../../Video';

import jWS_ShareTheLoveThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_JWS_ShareTheLove.jpeg';
import shareTheLoveImage1 from '../../../assets/img/stills/share_the_love/161007-DSC04950-10_Small.jpg';
import shareTheLoveImage2 from '../../../assets/img/stills/share_the_love/161007-DSC04958-11_Small.jpg';
import shareTheLoveImage3 from '../../../assets/img/stills/share_the_love/161007-DSC04982-15_Small.jpg';
import shareTheLoveImage4 from '../../../assets/img/stills/share_the_love/161007-DSC04988-16_Small.jpg';
import shareTheLoveImage5 from '../../../assets/img/stills/share_the_love/161007-DSC05016-20_Small.jpg';
import shareTheLoveImage6 from '../../../assets/img/stills/share_the_love/161007-DSC05046-21_Small.jpg';
import shareTheLoveImage7 from '../../../assets/img/stills/share_the_love/161007-DSC05069-23_Small.jpg';
import shareTheLoveImage8 from '../../../assets/img/stills/share_the_love/161007-DSC05096-28_Small.jpg';
import shareTheLoveImage9 from '../../../assets/img/stills/share_the_love/161007-DSC05131-30_Small.jpg';
import shareTheLoveImage10 from '../../../assets/img/stills/share_the_love/161007-DSC05146-33_Small.jpg';
import shareTheLoveImage11 from '../../../assets/img/stills/share_the_love/161008-DSC05301-42_Small.jpg';
import shareTheLoveImage12 from '../../../assets/img/stills/share_the_love/161008-DSC05310-44_Small.jpg';

export const shareTheLove = new Project({
  name: 'Share the Love',
  videos: [
    new Video({
      vimeoID: '217680415',
      name: 'Share the Love',
    }),
  ],
  url: 'sharethelove',
  description:
    'In October 2016, our team traveled to the Bahamas to film a commercial for world renowned artist Jane Waterous. However, when we landed we were informed that the small tropical storm that was expected to come had turned into a category 4 hurricane: the worst storm the area had seen in over 60 years. While hunkered down awaiting the storm to pass, we heard the negative things people were saying about how the Bahamas was preparing for the storm. What some seem to forget is that these are their homes, their lives, and their families. We are rarely shown the people and heart that goes into rebuilding and starting again, but tend to focus on the damage and destruction. We wanted to focus on the hope and pride of the people bringing back some happiness in a darker time. We were inspired by the connection built through this tragedy and the growth of a community coming together to support those in need.',
  client: clients.get(JANE_WATEROUS),
  credits: [
    { role: 'Direction', name: ['Ryan Lee', 'Nick Castel'] },
    { role: 'Produced', name: 'Bella Angelina Randazzo' },
    { role: 'Cinematography', name: ['Ryan Lee', 'Nick Castel'] },
    {
      role: 'Actresses and Choreography',
      name: ['Gabrielle Quilliam', 'Shannon Slade'],
    },
    { role: 'Construction and Lighting Manager', name: 'Misha Bychkov' },
    { role: 'Art Director', name: 'Danika Baker-Sohn' },
    { role: 'Edit and VFX', name: 'Ryan Lee' },
    {
      role: 'Makeup/Wardrobe',
      name: ['Bella Angelina Randazzo', 'Danika Baker-Sohn'],
    },
    {
      role: 'Production Assistance',
      name: [
        'Aramish Alvi',
        'Hanna Brynn',
        'Frederic Fontaine',
        'Akshay K Gupta',
        'Jacob Hawley',
        'Jackie Li',
        'Naseem Loloie',
        'Marshall McCann',
        'Mickayla Pyke',
      ],
    },
    { role: 'Behind the Scenes', name: 'Jessica Sung Jing Wan' },
    {
      role: 'Special Thanks',
      name: [
        'Janice Belanger',
        'Ryan Randall',
        'Alex Blaine',
        'Mina Khosravi',
        `Queen’s Film and Media Queen’s Food Services`,
      ],
    },
    { role: 'Sponsor', name: 'Epic Toronto Lowe’s Sound Works Sim Digital' },
    { role: 'Song', name: 'Point Point – F+L' },
  ],
  stills: [
    shareTheLoveImage1,
    shareTheLoveImage2,
    shareTheLoveImage3,
    shareTheLoveImage4,
    shareTheLoveImage5,
    shareTheLoveImage6,
    shareTheLoveImage7,
    shareTheLoveImage8,
    shareTheLoveImage9,
    shareTheLoveImage10,
    shareTheLoveImage11,
    shareTheLoveImage12,
  ],
  thumbnail: jWS_ShareTheLoveThumbnail,
});