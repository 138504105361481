import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Row, Col } from '../../components/Grid/Grid';
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';
import { Gallery } from '../../components/Gallery/Gallery';
import { projectURLs } from '../../models/projects/projects';
import { VideoCredit } from '../Video/VideoCredit';
import './Project.css';

export class ProjectViewCore extends Component {
  render() {
    const project = this.props.project;
    if (!project) {
      return (
        <Row>
          <Col xs={24} sm={18} md={16} lg={12} xl={12}>
            <h1>
              <span style={{ textTransform: 'uppercase' }}>
                Sorry, we couldn't find that project.
              </span>
            </h1>
          </Col>
        </Row>
      );
    }

    return (
      <div>
        <Helmet title={`alia | ${project.name}`} />
        {project.videos.length > 0 && <VideoPlayer video={project.videos[0]} />}
        <Row>
          <Col xs={24} sm={18} md={16} lg={12} xl={12}>
            <h1>
              <span style={{ textTransform: 'uppercase' }}>
                {project.client.name}
              </span>
              {' // '}
              <i>{project.name}</i>
            </h1>
            <p>{project.description}</p>
          </Col>

          <Col xs={24} sm={6} md={6} lg={4} xl={4}>
            {project.credits.length > 0 && (
              <div>
                <h2>Credits</h2>
                <div className="credits-container">
                  <div className="credits-preview">
                    {project.credits.map((credit) => (
                      <VideoCredit key={credit.name} credit={credit} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col
            xs={24}
            sm={22}
            md={22}
            lg={22}
            xl={22}
            className="project-video-list"
          >
            {project.videos.slice(1).map((video) => (
              <div key={video.name}>
                <h2>{video.name}</h2>
                <VideoPlayer video={video} />
              </div>
            ))}
          </Col>
        </Row>

        <Gallery stills={project.stills} />
      </div>
    );
  }
}

export class ProjectView extends Component {
  constructor(props) {
    super();

    // Either a project url or Vimeo ID
    const projectID = props.match.params.id;
    const project = projectURLs.get(projectID);

    this.state = {
      project,
    };
  }

  render() {
    return <ProjectViewCore {...this.state} />;
  }
}
