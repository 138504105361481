import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import ReactPhotoGallery from 'react-photo-gallery';
import { Row, Col } from '../../components/Grid/Grid';

export class Gallery extends Component {
  static propTypes = {
    stills: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
      stills: [],
    };
  }

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.stills !== prevProps.stills) {
      this.loadImages();
    }
  }

  loadImages() {
    Promise.all(this.props.stills.map(this.getImageSize))
      .then((stills) => {
        this.setState({
          stills,
        });
      })
      .catch(console.error);
  }

  setCurrentLightboxImage = (index) => {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  getImageSize(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      const onLoad = () => {
        const { width, height } = img;
        cleanup();
        resolve({
          src: url,
          width,
          height,
        });
      };

      const onError = (e) => {
        reject(e);
        cleanup();
      };

      const cleanup = () => {
        img.removeEventListener('load', onLoad);
        img.removeEventListener('error', onError);
        img.src = '';
      };

      img.addEventListener('load', onLoad, false);
      img.addEventListener('error', onError, false);
      img.src = url;
    });
  }

  render() {
    const { stills } = this.state;
    const columns = Math.max(Math.min(stills.length, 3), 1);

    const { currentImage, lightboxIsOpen } = this.state;
    return (
      <div>
        {stills.length > 0 && (
          <div>
            <Row>
              <Col xs={24} sm={24} md={22} lg={16} xl={16}>
                <h1>Stills</h1>
              </Col>
            </Row>
            <Lightbox
              images={this.props.stills.map((still) => ({ src: still }))}
              onClose={this.closeLightbox}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              currentImage={currentImage}
              isOpen={lightboxIsOpen}
            />
            <Row>
              <Col xs={24} sm={24} md={22} lg={16} xl={16}>
                <ReactPhotoGallery
                  photos={stills}
                  columns={columns}
                  onClick={(e, obj) => this.setCurrentLightboxImage(obj.index)}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
