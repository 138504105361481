import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ProjectListCore.css';
import {makeCancelable} from "../../util/promises";

export class VideoListRow extends Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      thumbnailURL: '',
    };
  }

  componentDidMount() {
    const { video } = this.props;
    const thumbnailURLPromise = makeCancelable(video.getThumbnailURL());
    thumbnailURLPromise.promise
      .then((thumbnailURL) => {
        this.setState({
          thumbnailURL,
        });
      })
      .catch(console.error);
    this.thumbnailURLPromise = thumbnailURLPromise;
  }

  componentWillUnmount() {
    this.thumbnailURLPromise.cancel();
  }

  isProject() {
    return !!this.props.video.videos;
  }

  render() {
    const { video } = this.props;
    const { thumbnailURL } = this.state;
    const url = `/work/${video.url}`;

    return (
      <Link to={url} className="work-list-row">
        <figure>
          <img src={thumbnailURL} alt={video.name} />
          <figcaption>
            <h2>
              <span style={{ textTransform: 'uppercase' }}>
                {video.client.name}
              </span>
              {' // '}
              <i>{video.name}</i>
            </h2>
          </figcaption>
        </figure>
      </Link>
    );
  }
}
