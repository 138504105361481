import {Project} from '../../Project';
import {clients, DESTINATION_ONTARIO} from '../../clients';
import {Video} from '../../Video';

import thumbnail from '../../../assets/img/stills/destination_ontario/wasaga/Thumb_1920x384_DONWasaga_v1.jpg';
import still1 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01200.jpg';
import still2 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01208.jpg';
import still3 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01273.jpg';
import still4 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01282.jpg';
import still5 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01303.jpg';
import still6 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01346.jpg';
import still7 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01363.jpg';
import still8 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01543.jpg';
import still9 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01547.jpg';
import still10 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01638.jpg';
import still11 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01652.jpg';
import still12 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01705.jpg';
import still13 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01761.jpg';
import still14 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01906.jpg';
import still15 from '../../../assets/img/stills/destination_ontario/wasaga/A7S01934.jpg';
import still16 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02174.jpg';
import still17 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02219.jpg';
import still18 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02264.jpg';
import still19 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02417.jpg';
import still20 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02565.jpg';
import still21 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02634.jpg';
import still22 from '../../../assets/img/stills/destination_ontario/wasaga/A7S02727.jpg';

export const wasaga = new Project({
  name: 'Wasaga',
  url: 'wasaga',
  client: clients.get(DESTINATION_ONTARIO),
  videos: [
    new Video({
      vimeoID: '359367746/3d802c71d9',
      name: 'Wasaga',
    }),
  ],
  stills: [
    still1,
    still2,
    still3,
    still4,
    still5,
    still6,
    still7,
    still8,
    still9,
    still10,
    still11,
    still12,
    still13,
    still14,
    still15,
    still16,
    still17,
    still18,
    still19,
    still20,
    still21,
    still22
  ],
  thumbnail,
  isPublic: false,
});
