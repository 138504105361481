import { createURLFromName } from './Client';

export class Project {
  constructor({
    name,
    url,
    client,
    description = '',
    videos = [],
    stills = [],
    isPublic = true,
    credits = [],
    thumbnail,
  }) {
    this.name = name;
    this.url = url || createURLFromName(name);
    this.description = description;
    this.client = client;
    this.videos = videos;
    this.stills = stills;
    this.isPublic = isPublic;
    this.credits = credits;
    this.thumbnail = thumbnail;
  }

  getThumbnailURL() {
    if (this.thumbnail) {
      return Promise.resolve(this.thumbnail);
    }

    if (this.videos.length > 0) {
      return this.videos[0].getThumbnailURL();
    }

    if (this.stills.length > 0) {
      return Promise.resolve(this.stills[0]);
    }

    return Promise.reject(`No thumbnail available for ${this.name}.`);
  }
}
