import React, { Component } from 'react';
import Layout from 'antd/lib/layout';
import './Footer.css';

import facebook from '../../assets/img/social_media/facebook.svg';
import instagram from '../../assets/img/social_media/instagram.svg';
import vimeo from '../../assets/img/social_media/vimeo.svg';
import { Row, Col } from '../Grid/Grid';

export class Footer extends Component {
  render() {
    return (
      <Layout.Footer className="footer">
        <Row>
          <Col xs={21} sm={21} md={21} lg={19}>
            <ul className="social-media">
              <li>
                <a href="https://vimeo.com/aliacreative">
                  <img src={vimeo} alt="Vimeo" className="social-media-icon" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/aliacreative/">
                  <img
                    src={instagram}
                    alt="Instagram"
                    className="social-media-icon"
                  />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Layout.Footer>
    );
  }
}
