import { Project } from '../../Project';
import { clients, TESLA } from '../../clients';
import { Video } from '../../Video';

import teslaSeekThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_Tesla_Seek.jpeg';
import teslaImage1 from '../../../assets/img/stills/tesla_seek/20170430-DSC02444-1_Small.jpg';
import teslaImage2 from '../../../assets/img/stills/tesla_seek/20170501-_DSC5638-19_Small.jpg';
import teslaImage3 from '../../../assets/img/stills/tesla_seek/20170501-DSC02537-5_Small.jpg';
import teslaImage4 from '../../../assets/img/stills/tesla_seek/20170501-DSC02542-6_Small.jpg';
import teslaImage5 from '../../../assets/img/stills/tesla_seek/20170501-DSC02549-8_Small.jpg';
import teslaImage6 from '../../../assets/img/stills/tesla_seek/20170501-DSC02560-13_Small.jpg';
import teslaImage7 from '../../../assets/img/stills/tesla_seek/20170501-DSC07963-3_Small.jpg';
import teslaImage8 from '../../../assets/img/stills/tesla_seek/20170501-DSC08640-18_Small.jpg';
import teslaImage9 from '../../../assets/img/stills/tesla_seek/20170503-DSC02707-2_Small.jpg';
import teslaImage10 from '../../../assets/img/stills/tesla_seek/20170503-DSC02722-15_Small.jpg';

export const seek = new Project({
  name: 'Seek',
  videos: [
    new Video({
      vimeoID: '219332648',
      name: 'Seek',
    }),
  ],
  url: 'seek',
  description:
    'Nature and people – society vs. the planet – we often create a divide between the two, yet the bond is unbreaking and undeniable. Though our lives are fundamentally dependent on the earth, we have forgotten how affected the earth is by our actions. We are at a time in history where the luxury to ignore our mistakes is coming to an end; a tipping point where we can no longer be blind, but must open our eyes and seek a change, seek the light.',
  client: clients.get(TESLA),
  credits: [
    { role: 'Co-Director and DoP', name: 'Ryan lee' },
    { role: 'Creative Director', name: 'Taylor Mansillo' },
    { role: 'Asst. Artistic Director/AC', name: 'Nicholas Castel' },
    { role: 'Producer', name: 'Bella Randazzo' },
    { role: 'Key Gaffer', name: 'Mathieu Sly' },
    { role: 'Grip/Swing', name: 'Will Culverwell' },
    { role: 'Drone Operator', name: ['Jing Wong', 'Kyle Chappell'] },
    {
      role: 'Production Assistant/Car Operator',
      name: ['Chris Munro', 'Akshay Gupta', 'Duncan Shaw', 'Jackie Li'],
    },
    { role: 'Car Operator', name: 'Boris Baker' },
    { role: 'Sound Design', name: 'Edward Eacueo' },
    { role: 'Audio Editor', name: ['Luc Ricci', 'Anthony Pansino'] },
    { role: 'Editor', name: 'Ryan Lee' },
    { role: 'Music', name: 'Low Roar' },
    {
      role: 'Cast',
      name: [
        'Cassandra Souter Willams',
        'Steve Zaire',
        'Saba Bokhari',
        'Lewis Gordon',
        'Darcy Cheung',
        'Griffen Rutherford',
      ],
    },
    {
      role: 'Special Thanks',
      name: [
        'Sheila Lee',
        'Ontario Camera',
        'Epic Toronto',
        'Monarch Butterflies',
        'Torri Richards',
        'Rob Baker and Leslie Galbraith',
      ],
    },
  ],
  stills: [
    teslaImage1,
    teslaImage2,
    teslaImage3,
    teslaImage4,
    teslaImage5,
    teslaImage6,
    teslaImage7,
    teslaImage8,
    teslaImage9,
    teslaImage10,
  ],
  thumbnail: teslaSeekThumbnail,
});
