import { createURLFromName } from './Client';
import { getVideo } from '../util/vimeo';

const MIN_ASPECT_RATIO = 2.5; // Minimum ratio of Width / Height
const MAX_ASPECT_RATIO = 16 / 9;
const HEADER_HEIGHT = 200;

export function getScreenWidth() {
  return window.innerWidth;
}

export function getScreenHeight() {
  return constrainHeight(window.innerHeight);
}

function constrainHeight(height) {
  return Math.round(
    Math.min(
      Math.max(height - HEADER_HEIGHT, getScreenWidth() / MIN_ASPECT_RATIO),
      getScreenWidth() / MAX_ASPECT_RATIO,
    ),
  );
}

export class Video {
  constructor({
    vimeoID,
    name,
    url,
    description = '',
    isPublic = true,
    client,
    credits = [],
    stills = [],
    thumbnail,
    width = 0,
    height = 0,
  }) {
    this.vimeoID = encodeURIComponent(vimeoID);
    this.name = name;
    this.url = url || createURLFromName(name);
    this.description = description;
    this.client = client;
    this.credits = credits;
    this.stills = stills;
    this.thumbnail = thumbnail;
    this.isPublic = isPublic;
    this.vimeoData = null;

    // Overrides actual video width and height
    this.width = width;
    this.height = height;
  }

  getScale() {
    const screenWidth = getScreenWidth();
    const screenHeight = getScreenHeight();

    const widthScale = Math.min(this.width, screenWidth) / this.width;
    const heightScale = Math.min(this.height, screenHeight) / this.height;
    const scale = Math.min(widthScale, heightScale);

    return scale;
  }

  getWidth() {
    const scale = this.getScale();
    if (!isNaN(scale)) {
      return Math.round(this.width * scale);
    }

    return getScreenWidth();
  }

  getHeight() {
    const scale = this.getScale();
    if (!isNaN(scale)) {
      return Math.round(this.height * scale);
    }

    if (this.vimeoData) {
      return constrainHeight(this.vimeoData.height);
    }

    return getScreenHeight();
  }

  getVimeoData(width, height) {
    if (this.vimeoData && width === this.width && height === this.height) {
      return Promise.resolve(this.vimeoData);
    }

    return getVideo(this.vimeoID, width, height).then((data) => {
      this.vimeoData = data;
      return data;
    });
  }

  getThumbnailURL() {
    if (this.thumbnail) {
      return Promise.resolve(this.thumbnail);
    }

    return this.getPreviewURL();
  }

  getPreviewURL() {
    const width = this.getWidth();
    const height = this.getHeight();
    return this.getVimeoData(width, height).then(
      ({ thumbnail_url }) => thumbnail_url,
    );
  }

  getVideoHTML() {
    const width = this.getWidth();
    const height = this.getHeight();
    return this.getVimeoData(width, height).then(({ html }) => html);
  }
}
