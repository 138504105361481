import { publicProjects } from '../../models/projects/projects';
import { ProjectListCore } from './ProjectListCore';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import {Row, Col} from "../../components/Grid/Grid";
import './ProjectList.css';

export class ProjectList extends React.Component {
  render() {
    return (
      <div>
        <Helmet title="alia | Work" />
        <Row gutter={0} style={{textAlign: 'center'}}>
          <Col xs={23} sm={23} md={19} lg={18} xl={18}>
            <h1>Work</h1>
            <p>
              Take a look at a selection of work with some of our amazing partners.
            </p>
          </Col>
        </Row>
        <ProjectListCore videos={publicProjects} />
      </div>
    );
  }
}
