import { Project } from '../../Project';
import { clients, JACK_ORG } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/jack/revolution_now/Thumb_1920x384_JCKSummitWrap2019_v2.jpg';

export const revolutionNow = new Project({
  name: 'Revolution Now',
  videos: [
    new Video({
      vimeoID: '321253743',
      name: 'Revolution Now',
    }),
  ],
  thumbnail,
  client: clients.get(JACK_ORG),
});
