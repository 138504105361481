import React from 'react';
import { Route } from 'react-router-dom';
import { ClientList } from './containers/Client/ClientList';
import { Home } from './containers/Home/Home';
import { About } from './containers/About/About';
import { Connect } from './containers/Connect/Connect';
import { ClientWorkList } from './containers/Client/ClientVideoList';
import { ProjectView } from './containers/Project/Project';
import { PrivateProjectList } from './containers/Project/PrivateProjectList';
import { ProjectList } from './containers/Project/ProjectList';

export const Routes = () => (
  <div>
    <Route exact path="/" component={Home} />
    <Route path="/about" component={About} exact />
    <Route path="/connect" component={Connect} exact />
    <Route path="/work" component={ProjectList} exact />
    <Route path="/work/:id" component={ProjectView} />
    <Route path="/private" component={PrivateProjectList} exact />
    <Route path="/client" component={ClientList} exact />
    <Route path="/client/:id" component={ClientWorkList} exact />
  </div>
);
