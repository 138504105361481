import { Project } from '../../Project';
import { clients, LAKEFIELD_COLLEGE_SCHOOL } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/lakefield_college_school/athletics/Thumb_1920x384_LCSAthFull_v3.jpg';

export const athletics = new Project({
  name: 'Athletics',
  url: 'athletics',
  client: clients.get(LAKEFIELD_COLLEGE_SCHOOL),
  videos: [
    new Video({
      vimeoID: '346423179',
      name: 'Athletics',
    }),
  ],
  thumbnail,
});
