import React, {Component} from 'react';
import Helmet from 'react-helmet';
import team from '../../assets/img/stills/tesla_seek/20170503-DSC02722-15_Small.jpg';
import {Row, Col} from '../../components/Grid/Grid';
import './About.css';

export class About extends Component {
  render() {
    return (
      <div className="page-about">
        <Helmet title="alia | About"/>
        <Row>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <h1>About</h1>
              </Col>
            </Row>
            <Row align="middle">
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <p>
                  Alia is a strategy-driven creative house specializing in emotion-focused content, with a fully
                  integrated production team.
                </p>
                <p>
                  We bridge the gap between the real and digital worlds to create connections between brands and their
                  audiences.
                </p>
                <p>
                  From mathematics and neuropsychology, to film and environmental science, our team is built on
                  diversity and collaboration. We have found that the best ideas develop when science and art intersect.
                </p>
                <p>
                  Our partners are from around the world, brought together by an impact-driven and human-centered focus.
                </p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <img src={team} alt="Team" className="about-team"/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
