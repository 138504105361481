import 'whatwg-fetch';
import 'core-js/es6/promise';
import { objectToURL } from './objectToURL';

const VIMEO_URL = 'https://vimeo.com/';
const VIMEO_API_URL = 'https://vimeo.com/api/oembed.json';

export function getVideo(id, width, height) {
  const videoURL = encodeURIComponent(VIMEO_URL + decodeURIComponent(id));
  const url = objectToURL(VIMEO_API_URL, {
    url: videoURL,
    api: true,
    autoplay: true,
    maxwidth: width,
    maxheight: height,
  });

  return fetch(url).then((response) => response.json());
}
