export class Client {
  constructor({ name, url, stills = [] }) {
    this.name = name;
    this.url = url || createURLFromName(name);
    this.stills = stills;
  }
}

export function createURLFromName(name) {
  return name.replace(/\W/g, '').toLowerCase();
}
