import { Project } from '../../Project';
import { clients, DESTINATION_ONTARIO } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/destination_ontario/niagara/Thumb_1920x384_DONNiagara_v1.jpg';

export const niagara = new Project({
  name: 'Niagara',
  url: 'niagara',
  client: clients.get(DESTINATION_ONTARIO),
  videos: [
    new Video({
      vimeoID: '350587229/24e7e444ab',
      name: 'Niagara',
    }),
  ],
  thumbnail,
  isPublic: false,
});
