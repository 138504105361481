import { Project } from '../../Project';
import { clients, DESTINATION_ONTARIO } from '../../clients';
import { Video } from '../../Video';

import eloraThumbnail from '../../../assets/img/stills/destination_ontario/elora/Thumb_1920x384_Elora_v1.jpg';
import elora1 from '../../../assets/img/stills/destination_ontario/elora/DSC09072_20180710_002-1.jpg';
import elora2 from '../../../assets/img/stills/destination_ontario/elora/DSC09103_20180710_014-2.jpg';
import elora3 from '../../../assets/img/stills/destination_ontario/elora/DSC09110_20180710_017-3.jpg';
import elora4 from '../../../assets/img/stills/destination_ontario/elora/DSC09118_20180710_018-4.jpg';
import elora5 from '../../../assets/img/stills/destination_ontario/elora/DSC09145_20180710_027-1.jpg';
import elora6 from '../../../assets/img/stills/destination_ontario/elora/DSC09155_20180710_029-6.jpg';
import elora7 from '../../../assets/img/stills/destination_ontario/elora/DSC09522_20180710_037-7.jpg';
import elora8 from '../../../assets/img/stills/destination_ontario/elora/DSC09524_20180710_038-8.jpg';
import elora9 from '../../../assets/img/stills/destination_ontario/elora/DSC09780_20180710_059-9.jpg';
import elora10 from '../../../assets/img/stills/destination_ontario/elora/DSC09813_20180710_074-10.jpg';
import elora11 from '../../../assets/img/stills/destination_ontario/elora/DSC09823_20180710_079-2.jpg';
import elora12 from '../../../assets/img/stills/destination_ontario/elora/DSC09851_20180710_083-12.jpg';

export const elora = new Project({
  name: 'Elora',
  url: 'elora',
  client: clients.get(DESTINATION_ONTARIO),
  videos: [
    new Video({
      vimeoID: '280131901/386e47cc22',
      name: 'Elora',
    }),
  ],
  stills: [
    elora1,
    elora2,
    elora3,
    elora4,
    elora5,
    elora6,
    elora7,
    elora8,
    elora9,
    elora10,
    elora11,
    elora12,
  ],
  thumbnail: eloraThumbnail,
  isPublic: false,
});
