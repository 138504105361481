import { Project } from '../../Project';
import { clients, JACK_ORG } from '../../clients';
import { Video } from '../../Video';

import jCK_SummitIntroThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_JCK_SummitIntro.jpeg';
import jackImage1 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_1.jpg';
import jackImage2 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_2.jpg';
import jackImage3 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_3.jpg';
import jackImage4 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_4.jpg';
import jackImage5 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_5.jpg';
import jackImage6 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_6.jpg';
import jackImage7 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_7.jpg';
import jackImage8 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_8.jpg';
import jackImage9 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_9.jpg';
import jackImage10 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_10.jpg';
import jackImage11 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_12.jpg';
import jackImage12 from '../../../assets/img/stills/jack_summit/jack_summit_intro_small_13.jpg';

export const rebelsWithACause = new Project({
  name: 'Rebels with a Cause',
  videos: [
    new Video({
      vimeoID: '258211286',
      name: 'Rebels with a Cause',
    }),
  ],
  url: 'rebelswithacause',
  description:
    'There’s something wrong here, something a lot of people are ignoring. 75% of my friends are not getting the help that they need or deserve. This needs to change. We will no longer sit back and not watch change come, we are rebels with a cause; a cause to bring more awareness and resources to mental health care.',
  client: clients.get(JACK_ORG),
  credits: [
    { role: 'Strategy/Production', name: 'Alia' },
    { role: 'Direction/Edit', name: 'Ryan Lee' },
    { role: 'Creative Director', name: 'Taylor Mansillo' },
    { role: 'Producer', name: 'Haley Piett' },
    { role: 'Grip', name: 'Chris Munro' },
  ],
  stills: [
    jackImage10,
    jackImage11,
    jackImage12,
    jackImage1,
    jackImage2,
    jackImage3,
    jackImage4,
    jackImage5,
    jackImage6,
    jackImage7,
    jackImage8,
    jackImage9,
  ],
  thumbnail: jCK_SummitIntroThumbnail,
});
