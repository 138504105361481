import { Project } from '../../Project';
import { clients, JP_WISERS } from '../../clients';
import { Video } from '../../Video';

import jpWisersImage1 from '../../../assets/img/stills/jp_wisers/Thumb_1920x384_JPWxNHL_Group.jpg';
import alumniWhiskeySeries2018Thumbnail from '../../../assets/img/stills/alumni_whiskey_series_2018/Thumb_1920x384_JPWxNHL_Bottle_v2.jpg';
import alumniWhiskeySeries2018Image1 from '../../../assets/img/stills/alumni_whiskey_series_2018/1_MIAB9914-180731-127_Small.jpg';
import alumniWhiskeySeries2018Image2 from '../../../assets/img/stills/alumni_whiskey_series_2018/2_MIAB9684-180730-94_Small.jpg';
import alumniWhiskeySeries2018Image3 from '../../../assets/img/stills/alumni_whiskey_series_2018/3_MIAB9825-180730-119_Small.jpg';
import alumniWhiskeySeries2018Image4 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9058-180730-2_Small.jpg';
import alumniWhiskeySeries2018Image5 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9191-180730-16_Small.jpg';
import alumniWhiskeySeries2018Image6 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9261-180730-31_Small.jpg';
import alumniWhiskeySeries2018Image7 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9311-180730-41_Small.jpg';
import alumniWhiskeySeries2018Image8 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9379-180730-51_Small.jpg';
import alumniWhiskeySeries2018Image9 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9441-180730-60_Small.jpg';
import alumniWhiskeySeries2018Image10 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9463-180730-67_Small.jpg';
import alumniWhiskeySeries2018Image11 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9471-180730-69_Small.jpg';
import alumniWhiskeySeries2018Image12 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9510-180730-75_Small.jpg';
import alumniWhiskeySeries2018Image13 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9600-180730-81_Small.jpg';
import alumniWhiskeySeries2018Image14 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9684-180730-1_Small.jpg';
import alumniWhiskeySeries2018Image15 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9731-180730-97_Small.jpg';
import alumniWhiskeySeries2018Image16 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9768-180730-101_Small.jpg';
import alumniWhiskeySeries2018Image17 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9780-180730-107_Small.jpg';
import alumniWhiskeySeries2018Image18 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9801-180730-113_Small.jpg';
import alumniWhiskeySeries2018Image19 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9807-180730-115_Small.jpg';
import alumniWhiskeySeries2018Image21 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9867-180730-123_Small.jpg';
import alumniWhiskeySeries2018Image22 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9919-180731-129_Small.jpg';
import alumniWhiskeySeries2018Image23 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9924-180731-132_Small.jpg';
import alumniWhiskeySeries2018Image24 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9945-180731-136_Small.jpg';
import alumniWhiskeySeries2018Image25 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9959-180731-139_Small.jpg';
import alumniWhiskeySeries2018Image26 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9969-180731-142_Small.jpg';
import alumniWhiskeySeries2018Image27 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9983-180731-143_Small.jpg';
import alumniWhiskeySeries2018Image28 from '../../../assets/img/stills/alumni_whiskey_series_2018/MIAB9989-180731-145_Small.jpg';

export const alumniWhiskeySeries2018 = new Project({
  name: 'The Alumni Whisky Series 2018',
  url: 'alumniwhiskyseries',
  client: clients.get(JP_WISERS),
  videos: [
    new Video({
      vimeoID: '288452738/62c1c55500',
      name: 'The Alumni Whiskey Series 2018',
    }),
    new Video({
      vimeoID: '288452390/b3f6fe019f',
      name: 'The Wendel Clark',
      width: 1080,
      height: 1920,
    }),
    new Video({
      vimeoID: '288452261/5c75144ef8',
      name: 'The Lanny McDonald',
      width: 1080,
      height: 1920,
    }),
    new Video({
      vimeoID: '288452060/f30a9618a7',
      name: 'The Guy Lafleur',
      width: 1080,
      height: 1920,
    }),
  ],
  stills: [
    alumniWhiskeySeries2018Image1,
    alumniWhiskeySeries2018Image2,
    alumniWhiskeySeries2018Image3,
    alumniWhiskeySeries2018Image4,
    alumniWhiskeySeries2018Image5,
    alumniWhiskeySeries2018Image6,
    alumniWhiskeySeries2018Image7,
    alumniWhiskeySeries2018Image8,
    alumniWhiskeySeries2018Image9,
    alumniWhiskeySeries2018Image10,
    alumniWhiskeySeries2018Image11,
    alumniWhiskeySeries2018Image12,
    alumniWhiskeySeries2018Image13,
    alumniWhiskeySeries2018Image14,
    alumniWhiskeySeries2018Image15,
    alumniWhiskeySeries2018Image16,
    alumniWhiskeySeries2018Image17,
    alumniWhiskeySeries2018Image18,
    alumniWhiskeySeries2018Image19,
    alumniWhiskeySeries2018Image21,
    alumniWhiskeySeries2018Image22,
    alumniWhiskeySeries2018Image23,
    alumniWhiskeySeries2018Image24,
    alumniWhiskeySeries2018Image25,
    alumniWhiskeySeries2018Image26,
    alumniWhiskeySeries2018Image27,
    alumniWhiskeySeries2018Image28,
  ],
  thumbnail: alumniWhiskeySeries2018Thumbnail,
  isPublic: false,
});

export const alumniWhiskeySeries2018Stills = new Project({
  name: 'The Alumni Whisky Series 2018 Stills',
  url: 'alumniwhiskyseriesstills',
  client: clients.get(JP_WISERS),
  stills: [
    alumniWhiskeySeries2018Image1,
    alumniWhiskeySeries2018Image2,
    alumniWhiskeySeries2018Image3,
    alumniWhiskeySeries2018Image4,
    alumniWhiskeySeries2018Image5,
    alumniWhiskeySeries2018Image6,
    alumniWhiskeySeries2018Image7,
    alumniWhiskeySeries2018Image8,
    alumniWhiskeySeries2018Image9,
    alumniWhiskeySeries2018Image10,
    alumniWhiskeySeries2018Image11,
    alumniWhiskeySeries2018Image12,
    alumniWhiskeySeries2018Image13,
    alumniWhiskeySeries2018Image14,
    alumniWhiskeySeries2018Image15,
    alumniWhiskeySeries2018Image16,
    alumniWhiskeySeries2018Image17,
    alumniWhiskeySeries2018Image18,
    alumniWhiskeySeries2018Image19,
    alumniWhiskeySeries2018Image21,
    alumniWhiskeySeries2018Image22,
    alumniWhiskeySeries2018Image23,
    alumniWhiskeySeries2018Image24,
    alumniWhiskeySeries2018Image25,
    alumniWhiskeySeries2018Image26,
    alumniWhiskeySeries2018Image27,
    alumniWhiskeySeries2018Image28,
  ],
  thumbnail: jpWisersImage1,
  isPublic: false,
});