import { Client } from './Client';

export const LAKEFIELD_COLLEGE_SCHOOL = 'Lakefield College School';
export const JP_WISERS = `J.P. Wiser's`;
export const DESTINATION_ONTARIO = 'Destination Ontario';
export const TESLA = 'Tesla';
export const JANE_WATEROUS = 'Jane Waterous';
export const QUEENS_UNIVERSITY = `Queen's University`;
export const YELLOW_BUS_FOUNDATION = 'Yellow Bus Foundation';
export const JACK_ORG = 'Jack.org';
export const ALIA = 'Alia';
export const LALELA = 'Lalela';
export const ROBBINS_HEBREW_ACADEMY = 'Robbins Hebrew Academy';

export const clientArray = [
  new Client({
    name: LAKEFIELD_COLLEGE_SCHOOL,
    url: 'lcs',
  }),
  new Client({
    name: JP_WISERS,
  }),
  new Client({
    name: DESTINATION_ONTARIO,
  }),
  new Client({
    name: TESLA,
  }),
  new Client({
    name: JANE_WATEROUS,
  }),
  new Client({
    name: QUEENS_UNIVERSITY,
    url: 'queensu',
  }),
  new Client({
    name: YELLOW_BUS_FOUNDATION,
  }),
  new Client({
    name: JACK_ORG,
  }),
  new Client({
    name: ALIA,
  }),
  new Client({
    name: LALELA,
  }),
  new Client({
    name: ROBBINS_HEBREW_ACADEMY,
  }),
];

export const clients = new Map(
  clientArray.map((client) => [client.name, client]),
);

export const clientURLs = new Map(
  clientArray.map((client) => [client.url, client]),
);
