import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Row, Col } from '../../components/Grid/Grid';
import connectImage from '../../assets/img/nature/180324_DSC08147_532.jpg';
import './Connect.css';

export class Connect extends Component {
  render() {
    return (
      <div className="page-connect">
        <Helmet title="alia | Connect" />
        <Row>
          <Col span={19}>
            <h1>Connect With Us</h1>
            <p>
              Taylor Mansillo
              <br />
              CSO, Partner
              <br />
              647 669 6295
              <br />
              taylor@aliacreative.ca
            </p>
            <img src={connectImage} alt="Team" className="about-team" />
          </Col>
        </Row>
      </div>
    );
  }
}
