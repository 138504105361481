import { Project } from '../../Project';
import { clients, ALIA } from '../../clients';
import { Video } from '../../Video';

import fadingLightsThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_FadingLights.jpeg';
import fadingLightsImage1 from '../../../assets/img/stills/fading_lights/_DSC9671_Small.jpg';
import fadingLightsImage2 from '../../../assets/img/stills/fading_lights/_DSC9710_Small.jpg';
import fadingLightsImage3 from '../../../assets/img/stills/fading_lights/_DSC9761_Small.jpg';
import fadingLightsImage4 from '../../../assets/img/stills/fading_lights/5J1A6597_Small.jpg';
import fadingLightsImage5 from '../../../assets/img/stills/fading_lights/5J1A6611_Small.jpg';
import fadingLightsImage6 from '../../../assets/img/stills/fading_lights/5J1A6640_Small.jpg';
import fadingLightsImage7 from '../../../assets/img/stills/fading_lights/5J1A6677_Small.jpg';
import fadingLightsImage8 from '../../../assets/img/stills/fading_lights/5J1A6813_Small.jpg';
import fadingLightsImage9 from '../../../assets/img/stills/fading_lights/5J1A6831_Small.jpg';
import fadingLightsImage10 from '../../../assets/img/stills/fading_lights/5J1A6991_Small.jpg';
import fadingLightsImage11 from '../../../assets/img/stills/fading_lights/5J1A7031_Small.jpg';
import fadingLightsImage12 from '../../../assets/img/stills/fading_lights/5J1A7035_Small.jpg';
import fadingLightsImage13 from '../../../assets/img/stills/fading_lights/DSC_9559_Small.jpg';
import fadingLightsImage14 from '../../../assets/img/stills/fading_lights/DSC_9571_Small.jpg';
import fadingLightsImage15 from '../../../assets/img/stills/fading_lights/DSC_9573_Small.jpg';
import fadingLightsImage16 from '../../../assets/img/stills/fading_lights/DSC00471-9_Small.jpg';
import fadingLightsImage17 from '../../../assets/img/stills/fading_lights/DSC00509-24_Small.jpg';
import fadingLightsImage18 from '../../../assets/img/stills/fading_lights/DSC00523-27_Small.jpg';
import fadingLightsImage19 from '../../../assets/img/stills/fading_lights/DSC00534-31_Small.jpg';
import fadingLightsImage20 from '../../../assets/img/stills/fading_lights/DSC00562-49_Small.jpg';
import fadingLightsImage21 from '../../../assets/img/stills/fading_lights/DSC00571-55_Small.jpg';
import fadingLightsImage22 from '../../../assets/img/stills/fading_lights/DSC00574-56_Small.jpg';
import fadingLightsImage23 from '../../../assets/img/stills/fading_lights/DSC00632-3_Small.jpg';
import fadingLightsImage24 from '../../../assets/img/stills/fading_lights/DSC00646-9_Small.jpg';
import fadingLightsImage25 from '../../../assets/img/stills/fading_lights/DSC00652-12_Small.jpg';
import fadingLightsImage26 from '../../../assets/img/stills/fading_lights/DSC00721-32_Small.jpg';
import fadingLightsImage27 from '../../../assets/img/stills/fading_lights/DSC01003-33_Small.jpg';
import fadingLightsImage28 from '../../../assets/img/stills/fading_lights/DSC01009-34_Small.jpg';
import fadingLightsImage29 from '../../../assets/img/stills/fading_lights/dundercastel-26_Small.jpg';
import fadingLightsImage30 from '../../../assets/img/stills/fading_lights/dundercastel-69_Small.jpg';
import fadingLightsImage31 from '../../../assets/img/stills/fading_lights/IMG_1060_Small.jpg';
import fadingLightsImage32 from '../../../assets/img/stills/fading_lights/IMG_1074_Small.jpg';
import fadingLightsImage33 from '../../../assets/img/stills/fading_lights/IMG_1082_Small.jpg';

export const fadingLights = new Project({
  name: 'Fading Lights',
  videos: [
    new Video({
      vimeoID: '164415107',
      name: 'Fading Lights',
    }),
  ],
  url: 'fadinglights',
  description:
    'With a diverse team of singers, dancers, designers, electrical engineers, composers, and more, we love exploring the different realms of art and creativity. What started off as just a song flourished into a desire for exploration and imagination. Fading Lights allowed us to explore the boundaries of mirrors and lights while also giving us the opportunity to explore the movement of dance. The audience is left to interpret the story freely by allowing their emotions and experiences to connect them to the story being told.',
  client: clients.get(ALIA),
  stills: [
    fadingLightsImage1,
    fadingLightsImage2,
    fadingLightsImage3,
    fadingLightsImage4,
    fadingLightsImage5,
    fadingLightsImage6,
    fadingLightsImage7,
    fadingLightsImage8,
    fadingLightsImage9,
    fadingLightsImage10,
    fadingLightsImage11,
    fadingLightsImage12,
    fadingLightsImage13,
    fadingLightsImage14,
    fadingLightsImage15,
    fadingLightsImage16,
    fadingLightsImage17,
    fadingLightsImage18,
    fadingLightsImage19,
    fadingLightsImage20,
    fadingLightsImage21,
    fadingLightsImage22,
    fadingLightsImage23,
    fadingLightsImage24,
    fadingLightsImage25,
    fadingLightsImage26,
    fadingLightsImage27,
    fadingLightsImage28,
    fadingLightsImage29,
    fadingLightsImage30,
    fadingLightsImage31,
    fadingLightsImage32,
    fadingLightsImage33,
  ],
  thumbnail: fadingLightsThumbnail,
  isPublic: false,
});
