import { Project } from '../../Project';
import { clients, LAKEFIELD_COLLEGE_SCHOOL } from '../../clients';
import { Video } from '../../Video';

import iAmATerrapinThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_LCS_Terrapin.jpeg';

export const iAmATerrapin = new Project({
  name: 'I Am a Terrapin',
  videos: [
    new Video({
      vimeoID: '183081237',
      name: 'I Am a Terrapin',
    }),
  ],
  url: 'iamaterrapin',
  description:
    'The pride of place that so many feel for this school is celebrated by the beautiful spirit and nature of our community. Although all different and diverse, it is the connection they have to Lakefield that brings them together.',
  client: clients.get(LAKEFIELD_COLLEGE_SCHOOL),
  thumbnail: iAmATerrapinThumbnail,
});
