import { Project } from '../../Project';
import { clients, QUEENS_UNIVERSITY } from '../../clients';
import { Video } from '../../Video';

import foreverTricolourThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_ForeverTricolour.jpg';
import foreverTricolourImage1 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_2.jpg';
import foreverTricolourImage2 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_3.jpg';
import foreverTricolourImage3 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_4.jpg';
import foreverTricolourImage4 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_6.jpg';
import foreverTricolourImage5 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_8.jpg';
import foreverTricolourImage6 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_9.jpg';
import foreverTricolourImage7 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_10.jpg';
import foreverTricolourImage8 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_small_1.jpg';
import foreverTricolourImage9 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_small_2.jpg';
import foreverTricolourImage10 from '../../../assets/img/stills/forever_tricolour/forever_tricolour_small_3.jpg';

export const foreverTricolour = new Project({
  name: 'Forever Tricolour',
  videos: [
    new Video({
      vimeoID: '145155148',
      name: 'Forever Tricolour',
    }),
  ],
  url: 'forevertricolour',
  description:
    'Tradition and Pride is what connects the older generation of students to the new. Although buildings may change and names may shift, our pride and tradition stays the same. We are rooted in our history and proud of our heritage. Our colours remind us of the endless memories, laughs, and friends made. They are a symbol of our time here at Queen’s and will always be our connection - Forever Tricolour.',
  client: clients.get(QUEENS_UNIVERSITY),
  stills: [
    foreverTricolourImage1,
    foreverTricolourImage2,
    foreverTricolourImage3,
    foreverTricolourImage4,
    foreverTricolourImage5,
    foreverTricolourImage6,
    foreverTricolourImage7,
    foreverTricolourImage8,
    foreverTricolourImage9,
    foreverTricolourImage10,
  ],
  thumbnail: foreverTricolourThumbnail,
});