import { Project } from '../../Project';
import { ALIA, clients } from '../../clients';
import { Video } from '../../Video';

import reelThumbnail from '../../../assets/img/stills/alia/reel/Thumb_1920x384_AliaReel.jpg';

export const reel = new Project({
  name: 'Reel',
  url: 'reel',
  client: clients.get(ALIA),
  videos: [
    new Video({
      vimeoID: '263986108/ee6df62f20',
      name: 'where strategy meets artistry',
    }),
  ],
  thumbnail: reelThumbnail,
  isPublic: false,
});
