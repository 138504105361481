import { Project } from '../../Project';
import { clients, JACK_ORG } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/jack/our_table/Thumb_1920x384_JCKSummitIntro2019_v1.jpg';

export const ourTable = new Project({
  name: 'Our Table',
  videos: [
    new Video({
      vimeoID: '320593928',
      name: 'Our Table',
    }),
  ],
  thumbnail,
  client: clients.get(JACK_ORG),
});
