import * as React from 'react';
import { Project } from '../../Project';
import { clients, LALELA } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/colors_of_ubuntu/Thumb_1920x384_Lalela_v2.jpg';
import colorsOfUbuntuImage1 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170704_001_small.jpg';
import colorsOfUbuntuImage2 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170704_002_small.jpg';
import colorsOfUbuntuImage3 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170705_003_small.jpg';
import colorsOfUbuntuImage4 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170705_004_small.jpg';
import colorsOfUbuntuImage5 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170705_005_small.jpg';
import colorsOfUbuntuImage6 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170705_006_small.jpg';
import colorsOfUbuntuImage7 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170705_007_small.jpg';
import colorsOfUbuntuImage9 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170705_009_small.jpg';
import colorsOfUbuntuImage10 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170706_010_small.jpg';
import colorsOfUbuntuImage11 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170706_011_small.jpg';
import colorsOfUbuntuImage12 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170706_012_small.jpg';
import colorsOfUbuntuImage13 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170707_013_small.jpg';
import colorsOfUbuntuImage14 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170707_014_small.jpg';
import colorsOfUbuntuImage15 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_015_small.jpg';
import colorsOfUbuntuImage17 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_017_small.jpg';
import colorsOfUbuntuImage18 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_018_small.jpg';
import colorsOfUbuntuImage19 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_019_small.jpg';
import colorsOfUbuntuImage20 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_020_small.jpg';
import colorsOfUbuntuImage21 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_021_small.jpg';
import colorsOfUbuntuImage22 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_022_small.jpg';
import colorsOfUbuntuImage23 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_023_small.jpg';
import colorsOfUbuntuImage24 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_024_small.jpg';
import colorsOfUbuntuImage25 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170710_025_small.jpg';
import colorsOfUbuntuImage26 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170711_026_small.jpg';
import colorsOfUbuntuImage27 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170711_027_small.jpg';
import colorsOfUbuntuImage28 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170711_028_small.jpg';
import colorsOfUbuntuImage29 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170711_029_small.jpg';
import colorsOfUbuntuImage30 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170714_030_small.jpg';
import colorsOfUbuntuImage31 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170716_031_small.jpg';
import colorsOfUbuntuImage32 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170718_032_small.jpg';
import colorsOfUbuntuImage34 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170718_034_small.jpg';
import colorsOfUbuntuImage35 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170718_035_small.jpg';
import colorsOfUbuntuImage36 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170718_036_small.jpg';
import colorsOfUbuntuImage37 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170718_037_small.jpg';
import colorsOfUbuntuImage38 from '../../../assets/img/stills/colors_of_ubuntu/bts_lalela_170718_038_small.jpg';
import colorsOfUbuntuImage39 from '../../../assets/img/stills/colors_of_ubuntu/DSC08892_180531_1.jpg';
import colorsOfUbuntuImage40 from '../../../assets/img/stills/colors_of_ubuntu/DSC08893_180531_2.jpg';
import colorsOfUbuntuImage41 from '../../../assets/img/stills/colors_of_ubuntu/DSC08896_180531_3.jpg';
import colorsOfUbuntuImage42 from '../../../assets/img/stills/colors_of_ubuntu/DSC08899_180531_4.jpg';
import colorsOfUbuntuImage43 from '../../../assets/img/stills/colors_of_ubuntu/DSC08915_180531_5.jpg';
import colorsOfUbuntuImage44 from '../../../assets/img/stills/colors_of_ubuntu/DSC08917_180531_6.jpg';
import colorsOfUbuntuImage45 from '../../../assets/img/stills/colors_of_ubuntu/DSC08921_180531_7.jpg';

export const colorsOfUbuntu = new Project({
  name: 'Colours of Ubuntu',
  videos: [
    new Video({
      vimeoID: '273438866/3ae0acb12e',
      name: 'Colours of Ubuntu',
    }),
  ],
  url: 'coloursofubuntu',
  description: (
    <div>
      <p>
        This is a story of humanity – a story of Ubuntu; the universal bond and
        sense of community at the core of every human.
      </p>
      <p>
        When violent riots broke out in Hout Bay, South Africa, the volunteers
        at Lalela found themselves with a decision to make.
      </p>
      <p>
        Amidst the violence, Lalela opened its doors for the children, providing
        not only a safe-haven, but a place where they could develop courage,
        strength, and perseverance in themselves, and as a community.
      </p>
      <p>
        Through the art projects, the children were prompted to find and portray
        their identities, and express what community means to them.
      </p>
      <p>
        We were soon surrounded by the most spirited of artists – facing a
        backdrop of a deep and complex history with a passionate people and
        breathtaking landscape.
      </p>
      <p>
        Colours of Ubuntu is a celebration of community, and the discovery of
        the role of art in the human spirit.
      </p>
      <hr />
      <p>
        Lalela provides educational arts for at-risk youth to spark creative
        thinking and awaken the entrepreneurial spirit.
      </p>
      <p>
        Find out more about their life changing art:
        <br />
        <a href="http://lalela.org">Lalela.org</a>
      </p>
    </div>
  ),
  client: clients.get(LALELA),
  credits: [
    { role: 'Agency', name: 'Alia' },
    { role: 'In Collaboration', name: ['Jane Waterous', 'Lalela'] },
    { role: 'Direction', name: ['Ryan Lee', 'Jane Waterous'] },
    { role: 'Composer', name: 'Erica Procunier' },
    { role: 'Edit', name: 'Ryan Lee' },
    { role: 'Art Program', name: 'Jane Waterous' },
    { role: 'Creative Director', name: 'Taylor Mansillo' },
    { role: 'Cinematography', name: 'Ryan Lee' },
    { role: 'Sound Design', name: 'Nicholas Castel' },
    { role: 'Music Mix', name: 'Gary Honess' },
    { role: 'Motion Graphics', name: 'Erin Prysiazny' },
    { role: 'Visual Effects', name: 'Ryan Lee' },
    { role: 'Production Assistant', name: 'Noah Rosen' },
    { role: 'Violin', name: ['Adam Despinic', 'Steve Koh'] },
    { role: 'Viola', name: 'Maxime Despax' },
    { role: 'Cello', name: 'Sebastian Ostertag' },
    {
      role: 'News Media',
      name: [
        'Afro Worldview',
        'Chronical Digital',
        'Africa News',
        'SABC News',
        'Ruvan Boshhoff',
      ],
    },
    {
      role: 'Special Thanks',
      name: ['Vanessa Kerzner', 'Kuhl Muzik', 'Interro Quartet'],
    },
  ],
  stills: [
    colorsOfUbuntuImage23,
    colorsOfUbuntuImage28,
    colorsOfUbuntuImage30,
    colorsOfUbuntuImage14,
    colorsOfUbuntuImage15,
    colorsOfUbuntuImage17,
    colorsOfUbuntuImage1,
    colorsOfUbuntuImage2,
    colorsOfUbuntuImage3,
    colorsOfUbuntuImage4,
    colorsOfUbuntuImage5,
    colorsOfUbuntuImage6,
    colorsOfUbuntuImage7,
    colorsOfUbuntuImage9,
    colorsOfUbuntuImage10,
    colorsOfUbuntuImage11,
    colorsOfUbuntuImage12,
    colorsOfUbuntuImage13,
    colorsOfUbuntuImage18,
    colorsOfUbuntuImage19,
    colorsOfUbuntuImage20,
    colorsOfUbuntuImage21,
    colorsOfUbuntuImage22,
    colorsOfUbuntuImage24,
    colorsOfUbuntuImage25,
    colorsOfUbuntuImage26,
    colorsOfUbuntuImage27,
    colorsOfUbuntuImage29,
    colorsOfUbuntuImage31,
    colorsOfUbuntuImage32,
    colorsOfUbuntuImage34,
    colorsOfUbuntuImage35,
    colorsOfUbuntuImage36,
    colorsOfUbuntuImage37,
    colorsOfUbuntuImage38,
    colorsOfUbuntuImage39,
    colorsOfUbuntuImage40,
    colorsOfUbuntuImage41,
    colorsOfUbuntuImage42,
    colorsOfUbuntuImage43,
    colorsOfUbuntuImage44,
    colorsOfUbuntuImage45,
  ],
  thumbnail: thumbnail,
});
