import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/img/logo.svg';
import logoWhite from '../../assets/img/logo_white.svg';
import './Logo.css';

export class Logo extends Component {
  static propTypes = {
    isLight: PropTypes.bool,
  };

  static defaultProps = {
    isLight: false,
  };

  render() {
    const { isLight } = this.props;
    const logoSource = isLight ? logoWhite : logo;

    return (
      <div className="logo">
        <img className="logo-image" src={logoSource} alt="Logo" />
      </div>
    );
  }
}
