import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { makeCancelable } from '../../util/promises';
import './VideoPlayer.css';

export class VideoPlayer extends Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
    previewStyle: PropTypes.object,
  };

  static defaultProps = {
    previewStyle: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      width: props.video.getWidth(),
      height: props.video.getHeight(),
      videoHTML: '',
      thumbnailURL: '',
      isPlaying: false,
    };
  }

  componentDidMount() {
    this.updateVideo();

    this.resizeVideo = debounce(() => this.updateVideo(), 300);
    window.addEventListener('resize', this.resizeVideo, false);
  }

  componentWillUnmount() {
    if (this.getVideoPromise) {
      this.getVideoPromise.cancel();
    }

    if (this.getPreviewPromise) {
      this.getPreviewPromise.cancel();
    }

    if (this.resizeVideo) {
      window.removeEventListener('resize', this.resizeVideo);
    }
  }

  updateVideo() {
    const getVideoPromise = makeCancelable(this.props.video.getVideoHTML());
    getVideoPromise.promise.then((videoHTML) => {
      const width = this.props.video.getWidth();
      const height = this.props.video.getHeight();
      this.setState({
        width,
        height,
      });

      // If the videoHTML is updated when the video is playing, the player will stop
      if (!this.state.videoHTML) {
        this.setState({
          videoHTML,
        });
      }
    });

    const getPreviewPromise = makeCancelable(
      getVideoPromise.promise.then(() => this.props.video.getPreviewURL()),
    );

    getPreviewPromise.promise
      .then((thumbnailURL) => {
        this.setState({
          thumbnailURL,
        });
      })
      .catch(console.error);

    this.getVideoPromise = getVideoPromise;
    this.getPreviewPromise = getPreviewPromise;
  }

  handleClickPlayButton = () => {
    this.setState({
      isPlaying: !this.state.isPlaying,
    });
  };

  render() {
    const { previewStyle, children } = this.props;
    const { isPlaying, videoHTML, thumbnailURL, width, height } = this.state;
    const previewStyleAttribute = {
      backgroundImage: `url(${thumbnailURL})`,
      height: `${height}px`,
      width: `${width}px`,
      ...previewStyle,
    };

    return (
      <TransitionGroup className="video-player-container">
        {!isPlaying ? (
          <CSSTransition key="preview" timeout={300} classNames="fade">
            <div className="video-player" style={previewStyleAttribute}>
              {children}
              <div
                className="play-button"
                onClick={this.handleClickPlayButton}
              />
            </div>
          </CSSTransition>
        ) : (
          <CSSTransition key="player" timeout={0} classNames="fade">
            <div className="video-player">
              <iframe
                src={this.getVideoURL(videoHTML)}
                width={width}
                height={height}
                frameBorder="0"
                title={this.getVideoTitle(videoHTML)}
                allowFullScreen
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }

  getVideoTitle(videoHTML) {
    const titleMatch = videoHTML.match(/title="(.+)"/);
    return titleMatch && titleMatch.length === 2 ? titleMatch[1] : '';
  }

  getVideoURL(videoHTML) {
    const srcMatch = videoHTML.match(/src="(.+)"/);
    return srcMatch && srcMatch.length === 2 ? srcMatch[1] : '';
  }
}
