import {Project} from '../../Project';
import {clients, ROBBINS_HEBREW_ACADEMY} from '../../clients';
import {Video} from '../../Video';

import thumbnail from './stills/Thumb_1920x384_RHADonors_v1.jpg';

export const extraordinary = new Project({
  name: 'Extraordinary',
  videos: [
    new Video({
      vimeoID: '296939918/bac07b28ab',
      name: 'Extraordinary',
    }),
  ],
  client: clients.get(ROBBINS_HEBREW_ACADEMY),
  thumbnail,
});
