import { Project } from '../../Project';
import { clients, DESTINATION_ONTARIO } from '../../clients';
import { Video } from '../../Video';

import veldThumbnail from '../../../assets/img/stills/veld/Thumb_1920x384_Veld2017_v2.jpg';
import veldImage1 from '../../../assets/img/stills/veld/bts_veld_170805_001_small.jpg';
import veldImage2 from '../../../assets/img/stills/veld/bts_veld_170805_002_small.jpg';
import veldImage3 from '../../../assets/img/stills/veld/bts_veld_170805_003_small.jpg';
import veldImage4 from '../../../assets/img/stills/veld/bts_veld_170805_004_small.jpg';
import veldImage5 from '../../../assets/img/stills/veld/bts_veld_170805_005_small.jpg';
import veldImage6 from '../../../assets/img/stills/veld/bts_veld_170805_006_small.jpg';
import veldImage7 from '../../../assets/img/stills/veld/bts_veld_170806_007_small.jpg';
import veldImage8 from '../../../assets/img/stills/veld/bts_veld_170806_008_small.jpg';
import veldImage9 from '../../../assets/img/stills/veld/bts_veld_170806_009_small.jpg';
import veldImage10 from '../../../assets/img/stills/veld/bts_veld_170806_010_small.jpg';
import veldImage11 from '../../../assets/img/stills/veld/bts_veld_170806_011_small.jpg';
import veldImage12 from '../../../assets/img/stills/veld/bts_veld_170806_012_small.jpg';

export const veld = new Project({
  name: 'Veld',
  videos: [
    new Video({
      vimeoID: '273907247/4c20dd8b2f',
      name: 'Veld',
    }),
  ],
  url: 'veld',
  client: clients.get(DESTINATION_ONTARIO),
  isPublic: false,
  stills: [
    veldImage1,
    veldImage2,
    veldImage3,
    veldImage4,
    veldImage5,
    veldImage6,
    veldImage7,
    veldImage8,
    veldImage9,
    veldImage10,
    veldImage11,
    veldImage12,
  ],
  thumbnail: veldThumbnail,
});
