import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from '../../components/Grid/Grid';
import { projects } from '../../models/projects/projects';
import { ProjectListCore } from './ProjectListCore';

export class PrivateProjectList extends Component {
  render() {
    const privateProjects = projects.filter((project) => !project.isPublic);

    return (
      <div>
        <Helmet title="alia | Private" />
        <Row gutter={0} style={{ textAlign: 'center' }}>
          <Col xs={23} sm={23} md={19} lg={18} xl={18}>
            <h1>Private Projects</h1>
          </Col>
        </Row>
        <ProjectListCore videos={privateProjects} />
      </div>
    );
  }
}
