import { Project } from '../../Project';
import { clients, LAKEFIELD_COLLEGE_SCHOOL } from '../../clients';
import { Video } from '../../Video';

import lCSAcademicsThumbnail from '../../../assets/img/thumbnails/Thumb_1920x384_LCSAcademics.jpeg';
import lcsAcademicsImage1 from '../../../assets/img/stills/lcs_perspective/02CED41A-56FF-4A8F-9C66-78759BA20AF6_Small.jpg';
import lcsAcademicsImage2 from '../../../assets/img/stills/lcs_perspective/2037DBD2-E47B-4732-B82E-FB6C9338ACE5_Small.jpg';
import lcsAcademicsImage4 from '../../../assets/img/stills/lcs_perspective/20171211-_DSC1820_Small.jpg';
import lcsAcademicsImage5 from '../../../assets/img/stills/lcs_perspective/20171211-_DSC1863_Small.jpg';
import lcsAcademicsImage8 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2028_Small.jpg';
import lcsAcademicsImage9 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2037_Small.jpg';
import lcsAcademicsImage10 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2043_Small.jpg';
import lcsAcademicsImage11 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2048_Small.jpg';
import lcsAcademicsImage12 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2050_Small.jpg';
import lcsAcademicsImage13 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2057_Small.jpg';
import lcsAcademicsImage14 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2071_Small.jpg';
import lcsAcademicsImage15 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2099_Small.jpg';
import lcsAcademicsImage16 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2115_Small.jpg';
import lcsAcademicsImage17 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2119_Small.jpg';
import lcsAcademicsImage19 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2151_Small.jpg';
import lcsAcademicsImage20 from '../../../assets/img/stills/lcs_perspective/20171212-_DSC2163_1_Small.jpg';
import lcsAcademicsImage21 from '../../../assets/img/stills/lcs_perspective/A3B1BB12-DC16-4FB6-82EA-4878063CF239_Small.jpg';
import lcsAcademicsImage22 from '../../../assets/img/stills/lcs_perspective/A29827F0-F3BD-4C73-9C1A-28BCA4CEA8F3_Small.jpg';
import lcsAcademicsImage23 from '../../../assets/img/stills/lcs_perspective/DF5C8559-6144-4FAF-80CA-0EECA39B587B_Small.jpg';
import lcsAcademicsImage24 from '../../../assets/img/stills/lcs_perspective/DSC02856_lakefield_bts_Small.jpg';
import lcsAcademicsImage25 from '../../../assets/img/stills/lcs_perspective/DSC03295_lakefield_bts_Small.jpg';

export const perspective = new Project({
  name: 'Perspective',
  videos: [
    new Video({
      vimeoID: '258031404',
      name: 'Perspective',
    }),
  ],
  url: 'perspective',
  description:
    "To show the strength and dynamisms of Lakefield College School's academics program, we collaborated with current student, Emilia Voudouris, to tell the story of her academic experience. Like her classmates, Emilia’s story is one where education knows no boundaries.",
  client: clients.get(LAKEFIELD_COLLEGE_SCHOOL),
  credits: [
    { role: 'Strategy/Production', name: 'Alia' },
    { role: 'Poem/Performance', name: 'Emilia Voudouris' },
    { role: 'Direction/Edit', name: 'Ryan Lee' },
    { role: 'Creative Director', name: 'Taylor Mansillo' },
    { role: 'Production Design', name: 'Cassandra Souter-Williams' },
    { role: 'First AC', name: 'Nicholas Castel' },
    { role: 'Grip', name: 'Marshall McCann' },
    { role: 'Gaffe/BTS', name: 'Jing Wong' },
  ],
  stills: [
    lcsAcademicsImage1,
    lcsAcademicsImage2,
    lcsAcademicsImage4,
    lcsAcademicsImage5,
    lcsAcademicsImage8,
    lcsAcademicsImage9,
    lcsAcademicsImage10,
    lcsAcademicsImage11,
    lcsAcademicsImage12,
    lcsAcademicsImage13,
    lcsAcademicsImage14,
    lcsAcademicsImage15,
    lcsAcademicsImage16,
    lcsAcademicsImage17,
    lcsAcademicsImage19,
    lcsAcademicsImage20,
    lcsAcademicsImage21,
    lcsAcademicsImage22,
    lcsAcademicsImage23,
    lcsAcademicsImage24,
    lcsAcademicsImage25,
  ],
  thumbnail: lCSAcademicsThumbnail,
});
