import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {Logo} from '../../components/Logo/Logo';
import {VideoPlayer} from '../../components/VideoPlayer/VideoPlayer';
import {demoReel} from '../../models/videos';
import caa from '../../assets/img/client_logos/caa.png';
import cppa from '../../assets/img/client_logos/cppa.png';
import gbc from '../../assets/img/client_logos/gbc.png';
import ibi from '../../assets/img/client_logos/ibi.png';
import jackorg from '../../assets/img/client_logos/jackorg.png';
import lalela from '../../assets/img/client_logos/lalela.png';
import lcs from '../../assets/img/client_logos/lcs.png';
import mto from '../../assets/img/client_logos/mto.png';
import oneandonly from '../../assets/img/client_logos/oneandonly.png';
import ontariotourism from '../../assets/img/client_logos/ontariotourism.png';
import queens from '../../assets/img/client_logos/queens.png';
import yellowbusfoundation from '../../assets/img/client_logos/yellowbusfoundation.jpg';
import nhlalumniassociation from '../../assets/img/client_logos/nhlalumniassociation.png';
import jpwisers from '../../assets/img/client_logos/jpwisers.png';
import {Row, Col} from '../../components/Grid/Grid';
import './Home.css';
import {ProjectListCore} from '../Project/ProjectListCore';
import {perspective} from "../../models/projects/lakefieldCollegeSchool/perspective";
import {rebelsWithACause} from "../../models/projects/jack/rebelsWithACause";
import {ourTable} from "../../models/projects/jack/ourTable";
import {revolutionNow} from "../../models/projects/jack/revolutionNow";
import {seek} from "../../models/projects/tesla/seek";
import {colorsOfUbuntu} from "../../models/projects/lalela/coloursOfUbuntu";
import {iAmATerrapin} from "../../models/projects/lakefieldCollegeSchool/iAmATerrapin";
import {shareTheLove} from "../../models/projects/janeWaterous/shareTheLove";
import {athletics} from "../../models/projects/lakefieldCollegeSchool/athletics";
import {athleticsSpring} from "../../models/projects/lakefieldCollegeSchool/athleticsSpring";
import {athleticsWinter} from "../../models/projects/lakefieldCollegeSchool/athleticsWinter";

const previewVideo = demoReel;

const homeProjects = [
  perspective,
  rebelsWithACause,
  seek,
  colorsOfUbuntu,
  athletics,
  iAmATerrapin,
  shareTheLove,
  athleticsSpring,
  ourTable,
  athleticsWinter,
  revolutionNow,
];

export class Home extends Component {
  render() {
    return (
      <div className="home-page">
        <VideoPlayer video={previewVideo}>
          <Logo isLight={true}/>
          <span className="slogan">{'// where strategy meets artistry'}</span>
        </VideoPlayer>
        <Row gutter={0} style={{textAlign: 'center'}}>
          <Col xs={23} sm={23} md={19} lg={18} xl={16}>
            <h1>We are connectors at heart.</h1>
            <p>
              We create human connections between brands with impact and their audiences; integrating novel strategy and
              dynamic production to invoke emotions.
            </p>
            <p>
              Our strategy and production teams are seamlessly integrated to create content that moves and matters.
            </p>
          </Col>
        </Row>
        <ProjectListCore videos={homeProjects}/>
        <Row>
          <Col xs={24} sm={24} md={19} lg={19} xl={19}>
            <h1 style={{textAlign: 'center'}}>Partners</h1>
            <ul className="clients-list">
              <li>
                <img src={ontariotourism} alt="Ontario Tourism"/>
              </li>
              <li>
                <img src={caa} alt="CAA"/>
              </li>
              <li>
                <img src={gbc} alt="GBC"/>
              </li>
              <li>
                <img src={ibi} alt="IBI"/>
              </li>
              <li>
                <img src={jackorg} alt="Jack.org"/>
              </li>
              <li>
                <img src={lalela} alt="Lalela"/>
              </li>
              <li>
                <img src={lcs} alt="LCS"/>
              </li>
              <li>
                <img src={mto} alt="MTO"/>
              </li>
              <li>
                <img src={oneandonly} alt="One & Only"/>
              </li>
              <li>
                <img src={jpwisers} alt="J.P. Wiser's"/>
              </li>
              <li>
                <img src={nhlalumniassociation} alt="NHL Alumni Association"/>
              </li>
              <li>
                <img src={queens} alt="Queens"/>
              </li>
              <li>
                <img src={yellowbusfoundation} alt="Yellow Bus Foundation"/>
              </li>
              <li>
                <img src={cppa} alt="CPPA"/>
              </li>
            </ul>
          </Col>
        </Row>
        <Helmet title="alia"/>
      </div>
    );
  }
}
