import { Project } from '../../Project';
import { clients, LAKEFIELD_COLLEGE_SCHOOL } from '../../clients';
import { Video } from '../../Video';

import thumbnail from '../../../assets/img/stills/lakefield_college_school/athletics_winter/Thumb_1920x384_LCSAthWinter_v1.jpg';

export const athleticsWinter= new Project({
  name: 'Athletics Winter',
  url: 'athleticswinter',
  client: clients.get(LAKEFIELD_COLLEGE_SCHOOL),
  videos: [
    new Video({
      vimeoID: '338042802',
      name: 'Athletics Winter',
    }),
  ],
  thumbnail,
});
