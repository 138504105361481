import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../components/Grid/Grid';
import { VideoListRow } from './ProjectListRow';
import './ProjectListCore.css';

export class ProjectListCore extends Component {
  static propTypes = {
    videos: PropTypes.array.isRequired,
  };

  render() {
    return (
      <Row>
        <Col
          xs={24}
          sm={24}
          md={19}
          lg={19}
          xl={19}
          className="work-list-component"
        >
          {this.props.videos.map((video) => (
            <VideoListRow key={video.name} video={video} />
          ))}
        </Col>
      </Row>
    );
  }
}