import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { projects } from '../../models/projects/projects';
import { Row, Col } from '../../components/Grid/Grid';
import { ProjectListCore } from '../Project/ProjectListCore';
import { clientURLs, clients } from '../../models/clients';
import { Gallery } from '../../components/Gallery/Gallery';

export class ClientWorkList extends Component {
  render() {
    const clientURL = decodeURIComponent(this.props.match.params.id);
    const client = clientURLs.get(clientURL) || clients.get(clientURL);
    if (!client) {
      return (
        <Row>
          <Col xs={24} sm={18} md={16} lg={12} xl={12}>
            <h1>
              <span style={{ textTransform: 'uppercase' }}>
                Sorry, we couldn't find that client.
              </span>
            </h1>
          </Col>
        </Row>
      );
    }

    const clientProjects = [...projects.values()].filter(
      (project) => project.client === client,
    );

    return (
      <div>
        <Helmet title={`alia | ${client.name}`} />
        <Row gutter={0} style={{ textAlign: 'center' }}>
          <Col xs={23} sm={23} md={19} lg={18} xl={18}>
            <h1>{client.name}</h1>
          </Col>
        </Row>
        <ProjectListCore videos={clientProjects} />
        <Gallery stills={client.stills} />
      </div>
    );
  }
}
